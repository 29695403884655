import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IsLoggedIn } from '../../../decorators/is-logged-in.decorator';
import { Menu } from '../../../domains/menu';
import { Restaurant } from '../../../domains/restaurant';
import { Basket } from '../../../models/basket/basket';
import { BasketService } from '../../../services/basket.service';
import { NavigatorService } from '../../../services/navigator.service';
import { TagService } from '../../../services/tag.service';

@Component({
  selector: 'app-navbar-bottom-menus',
  templateUrl: './navbar-bottom-menus.component.html',
  styleUrls: [
    '../navbar.shared.scss'
  ]
})
export class NavbarBottomMenusComponent implements OnInit, OnChanges {
  @Input() restaurant: Restaurant = null;
  @Input() rotateMainLogo = false;
  @Input() listView: boolean;

  @Output() mainMenuClick = new EventEmitter<boolean>();
  @Output() openPreviewModal = new EventEmitter<boolean>();

  @IsLoggedIn() isLoggedIn: boolean;

  menus: Menu[];
  basket: Basket;

  constructor(
    private tagService: TagService,
    private activatedRoute: ActivatedRoute,
    private basketService: BasketService,
    private navigatorService: NavigatorService
  ) {
    this.tagService.initTagsToView();

    this.basketService.basketSubject.subscribe(value => {
      this.basket = value;
    });
  }

  ngOnInit() {
    this.activatedRoute
      .params
      .subscribe(params => {
        setTimeout(() => {
          if (this.restaurant) {
            this.setMenusAndSort();
          }
        }, 100);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.hasOwnProperty('restaurant') &&
      changes.restaurant.previousValue !== changes.restaurant.currentValue
    ) {
      this.setMenusAndSort();
    }
  }

  private setMenusAndSort() {
    this.menus = this.restaurant?.menus;
    this.restaurant?.periodsGroupAndSort();
  }

  onMainMenuClick() {
    this.mainMenuClick.emit(true);
  }

  onPreviewModalClick() {
    this.openPreviewModal.emit(true);
  }

  openGoogleMap() {
    this.navigatorService.goToMapsRestaurant(this.restaurant);
  }
}
