<nav class="navbar navbar-top fixed-top bg-white max-width safe-area-top">
  <div class="navbar-top-left navbar-width-30px"></div>

  <a *ngIf="title" appHrefNone class="navbar-brand navbar-title mr-0 text-center" (click)="goBack()">
    {{ title }}
  </a>

  <div class="navbar-top-right navbar-width-30px">
    <a appHrefNone *ngIf="restaurant?.hasMenu && !editMode && restaurant?.isOwned" (click)="onViewChanged()">
      <i *ngIf="listView" class="icon list-mode"></i>
      <i *ngIf="!listView" class="icon two-two-mode"></i>
    </a>

    <div *ngIf="editMode">
      <a appHrefNone (click)="toggleEditMode(false)" class="btn btn-primary md-btn-flat pr-0"> Save </a>
    </div>
  </div>
</nav>

<ng-template #hoursEditModalRef let-c="close">
  <app-hours-edit-modal
    [restaurant]="restaurant"
    [explore]="true"
  ></app-hours-edit-modal>
</ng-template>
