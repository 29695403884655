import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CurrentUser } from '../../../decorators/current-user.decorator';
import { IsLoggedIn } from '../../../decorators/is-logged-in.decorator';
import { Restaurant } from '../../../domains/restaurant';
import { User } from '../../../domains/user';
import { BooleanLocalStorage } from '../../../local-storage/boolean-local-storage';
import { LocalStorageKey } from '../../../models/enums/local-storage-key';
import { ModalType } from '../../../models/enums/modal-type';
import { ModalService } from '../../../services/modal.service';
import { NavigatorService } from '../../../services/navigator.service';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-navbar-top-menu',
  templateUrl: './navbar-top-menu.component.html',
  styleUrls: ['../navbar.shared.scss']
})
export class NavbarTopMenuComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() listView: boolean;
  @Input() restaurant: Restaurant;
  @Input() editMode: boolean;
  @Input() isCs = false;

  @Output() toggle = new EventEmitter<boolean>();
  @Output() viewChanged = new EventEmitter<boolean>();

  @ViewChild('hoursEditModalRef') hoursEditModalRef: ElementRef;

  @IsLoggedIn() isLoggedIn: boolean;
  @CurrentUser() currentUser: User;

  constructor(
    public navigatorService: NavigatorService,
    private notificationService: NotificationService,
    private modalService: ModalService,
  ) {
  }

  ngOnInit() {
    const isEditNoteShowedLocalStorage = new BooleanLocalStorage(
      LocalStorageKey.IS_EDIT_NOTE_SHOWED,
      {hostname: this.restaurant.hostname, locationId: this.restaurant.locationIndex}
    );

    if (this.editMode && !isEditNoteShowedLocalStorage.getItem()) {
      isEditNoteShowedLocalStorage.setItem(true);

      if (this.restaurant.isScraped) {
        this.notificationService.editMode();
      } else {
        this.notificationService.restaurantCrated();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  goBack() {
    this.navigatorService.goToRestaurantFromSubPages(false, 200);
  }

  toggleEditMode(value: boolean) {
    this.editMode = value;
    this.toggle.emit(this.editMode);
  }

  openHoursEditModal() {
    this.modalService.open(this.hoursEditModalRef, ModalType.HOURS_EDIT);
  }

  get isAdmin(): boolean {
    return this.currentUser?.isAdmin(this.restaurant);
  }

  onViewChanged() {
    this.listView = !this.listView;
    this.viewChanged.emit(this.listView);
  }
}
