import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IsDevice } from '../../../../decorators/is-device.decorator';
import { ModalClose } from '../../../../decorators/modal-close.decorator';
import { CityWithChild } from '../../../../domains/city-with-child';
import { CountryWithChild } from '../../../../domains/country-with-child';
import { CountyWithChild } from '../../../../domains/county-with-child';
import { District } from '../../../../domains/district';
import { RegionWithChild } from '../../../../domains/region-with-child';
import { StateWithChild } from '../../../../domains/state-with-child';
import { DeviceType } from '../../../../models/enums/device-type';
import { SearchFiltersPosition } from '../../../../models/enums/search-filters-position';
import { Place } from '../../../../models/place';
import { SearchParams } from '../../../../models/search-params';
import { SearchFiltersPositionWrapper } from '../../../../models/wrappers/search-filters-position.wrapper';
import { HashUtils } from '../../../../utils/hash-utils';

@Component({
  selector: 'app-search-location-modal',
  templateUrl: './search-location-modal.component.html',
  styleUrls: ['./search-location-modal.component.scss']
})
export class SearchLocationModalComponent implements OnInit {
  @Input() searchParams: SearchParams;
  @Input() country: CountryWithChild;

  @Output() countySelectedChange = new EventEmitter<CountyWithChild>();
  @Output() regionSelectedChange = new EventEmitter<RegionWithChild>();
  @Output() citySelectedChange = new EventEmitter<CityWithChild>();
  @Output() districtSelectedChange = new EventEmitter<District>();
  @Output() localitySelectedChange = new EventEmitter<boolean>();
  @Output() stateSelectedChange = new EventEmitter<StateWithChild>();
  @Output() selectPlace = new EventEmitter<Place>();
  @Output() findMe = new EventEmitter<boolean>();
  @Output() selectRestaurant = new EventEmitter<boolean>();

  @ViewChild('cityInput', { static: false }) searchCityInput!: ElementRef;

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  searchValueExists = false;
  hash: string;
  stateSelected: StateWithChild = null;
  countySelected: CountyWithChild;
  regionSelected: RegionWithChild;
  citySelected: CityWithChild;
  districtSelected: District;
  isLocalitySelected: boolean;
  searchFiltersPositionMobile = new SearchFiltersPositionWrapper(SearchFiltersPosition.MOBILE);

  constructor() {
  }

  ngOnInit(): void {
    this.hash = HashUtils.getCodePositionUniqueHash();
  }

  searchInputValue(searchValue: string) {
    this.searchValueExists = searchValue !== '';
  }

  onSelectPlace(place: Place) {
    this.selectPlace.emit(place);
  }

  onFindMe() {
    this.findMe.emit(true);
  }

  @ModalClose()
  goBack() {
  }

  onStateChange(state: StateWithChild) {
    this.stateSelected = state;

    this.stateSelectedChange.emit(this.stateSelected);

    this.resetCityAndDistrict();
  }

  onCountyChange(county: CountyWithChild) {
    this.countySelected = county;

    this.resetCityAndDistrict();

    this.countySelectedChange.emit(this.countySelected);
  }

  onRegionChange(region: RegionWithChild) {
    this.regionSelected = region;

    this.resetCityAndDistrict();

    this.regionSelectedChange.emit(this.regionSelected);
  }

  onCityChange(city: CityWithChild) {
    this.citySelected = city;

    this.citySelectedChange.emit(this.citySelected);
  }

  onDistrictChange(district: District) {
    this.districtSelected = district;

    this.districtSelectedChange.emit(this.districtSelected);
  }

  private resetCityAndDistrict() {
    this.citySelected = null;
    this.districtSelected = null;
  }

  onLocalitySelectedChange(localitySelected: boolean) {
    setTimeout(() => {
      this.isLocalitySelected = localitySelected;
      this.localitySelectedChange.emit(localitySelected);
    });
  }

  onSelectRestaurant($event: boolean) {
    this.selectRestaurant.emit($event);

    this.goBack();
  }
}
