<div class="navbar-container max-width fixed-bottom d-flex safe-area-bottom">
  <nav class="navbar navbar-bottom bg-white flex-fill pr-0">
    <div class="icon-v2 location" (click)="openGoogleMap()"></div>

    <app-navbar-bottom-icon-explore [restaurant]="restaurant" [isRestaurant]="true"></app-navbar-bottom-icon-explore>

    <div class="visibility-hidden"></div>
  </nav>

  <app-navbar-bottom-semicircle-notch *ngIf="basket?.isEmpty()" (menuClick)="onMainMenuClick()">
    <i class="icon main-grey rotate-45"></i>
  </app-navbar-bottom-semicircle-notch>

  <nav
    *ngIf="!basket?.isEmpty()"
    class="navbar-main-menu navbar bg-white navbar-bottom px-0 flex-content-vc-hc"
  >
    <a appHrefNone (click)="onMainMenuClick()">
      <i class="icon main-grey rotate-45"></i>
    </a>
  </nav>

  <nav class="navbar navbar-bottom bg-white flex-fill pl-0">
    <div class="visibility-hidden"></div>

    <app-navbar-bottom-icon-recent
      [isRestaurant]="true"
    ></app-navbar-bottom-icon-recent>

    <div
      class="icon-v2 clock-dark"
      style="width: 31px; margin-bottom: 3px"
      (click)="onPreviewModalClick()"
    ></div>
  </nav>
</div>
